import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import NavbarComponent from "./NavbarComponent";
import Footer from "./Footer";

function Register() {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    name: "",
    lastname: "",
    dui: "",
    email: "",
    cellphone: "",
    date: "",
    time: "",
  });

  const [message, setMessage] = useState("");
  const [errors, setErrors] = useState({});
  const [isHovered, setIsHovered] = useState(false);

  const weekdayTimes = [
    "5:15 am - 6:15 am",
    "6:30 am - 7:30 am",
    "8:00 am - 9:00 am",
    "10:00 am - 11:00 am",
    "12:00 pm - 1:00 pm",
    "4:00 pm - 5:00 pm",
    "5:30 pm - 6:30 pm",
    "7:00 pm - 8:00 pm",
    "8:30 pm - 9:30 pm",
  ];

  const saturdayTimes = [
    "6:30 am - 7:30 am",
    "8:00 am - 9:00 am",
    "9:00 am - 10:00 am",
    "10:30 am - 11:30 am (YOGA)",
  ];

  const sundayTimes = ["10:15 am - 11:15 am (YOGA)"];

  const getAvailableTimes = (date) => {
    if (!date) return [];
    const selectedDate = new Date(date);
    const day = selectedDate.getDay(); // Sunday = 0, Monday = 1, ..., Saturday = 6
  
    if (day === 6) return sundayTimes;   // Sunday schedule
    if (day === 5) return saturdayTimes; // Saturday schedule
    return weekdayTimes;                 // Weekday schedule (Monday to Friday)
  };

  const availableTimes = getAvailableTimes(formData.date);

  const styles = {
    backgroundSection: {
      paddingTop: "100px",
      paddingBottom: "50px",
      backgroundImage: "url('/Comprimidas/headerbackground.jpg')",
      backgroundSize: "cover",
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      minHeight: "calc(100vh - 150px)",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    formContainer: {
      backgroundColor: "rgba(255, 255, 255, 0.9)",
      borderRadius: "10px",
      padding: "30px",
      maxWidth: "500px",
      width: "100%",
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    },
    mainTitle: {
      fontFamily: "Gendy, sans-serif",
      fontSize: "2.8rem",
      fontWeight: "bold",
      color: "#000000",
      textAlign: "center",
      marginBottom: "30px",
    },
    sectionContainer: {
      backgroundColor: "#ffffff",
      borderRadius: "10px",
      padding: "20px",
      marginBottom: "20px",
      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
    },
    sectionTitle: {
      fontFamily: "Montserrat, sans-serif",
      fontSize: "1.5rem",
      fontWeight: "bold",
      color: "#000000",
      marginBottom: "15px",
      textAlign: "left",
    },
    infoText: {
      fontFamily: "Montserrat, sans-serif",
      fontSize: "1rem",
      color: "#000000",
      marginBottom: "10px",
    },
    input: {
      width: "100%",
      padding: "10px",
      margin: "10px 0",
      borderRadius: "5px",
      border: "1px solid #ccc",
      fontFamily: "Montserrat, sans-serif",
      fontSize: "1rem",
    },
    errorText: {
      color: "red",
      fontSize: "0.9rem",
      marginBottom: "10px",
    },
    phoneInputContainer: {
      width: "100%",
      margin: "10px 0",
    },
    phoneInput: {
      width: "100%",
      padding: "10px",
      borderRadius: "5px",
      border: "1px solid #ccc",
      fontFamily: "Montserrat, sans-serif",
      fontSize: "1rem",
    },
    select: {
      width: "100%",
      padding: "10px",
      borderRadius: "5px",
      border: "1px solid #ccc",
      fontFamily: "Montserrat, sans-serif",
      fontSize: "1rem",
    },
    button: {
      fontFamily: "Montserrat, sans-serif",
      backgroundColor: "#1466C3",
      color: "#ffffff",
      border: "none",
      padding: "10px 20px",
      borderRadius: "5px",
      cursor: "pointer",
      width: "100%",
      fontWeight: "bold",
      transition: "background-color 0.3s ease, transform 0.2s ease",
    },
    buttonHover: {
      backgroundColor: "#0f4ea8",
      transform: "scale(1.05)",
    },
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
  
    // Enforce numeric-only input for DUI
    if (name === "dui") {
      // Allow only digits and restrict length to 9
      if (!/^\d{0,9}$/.test(value)) {
        return; // Ignore invalid input
      }
    }
  
    setFormData({ ...formData, [name]: value });
    setErrors((prev) => ({ ...prev, [name]: "" })); // Clear error for the field
  };
  
  const validateForm = () => {
    const newErrors = {};
  
    if (!formData.name) newErrors.name = "Nombre es requerido.";
    if (!formData.lastname) newErrors.lastname = "Apellido es requerido.";
  
    // DUI validation: only numbers, 8 or 9 digits long
    if (!formData.dui) {
      newErrors.dui = "DUI es requerido.";
    } else if (!/^\d{8,9}$/.test(formData.dui)) {
      newErrors.dui = "DUI debe contener solo números y ser de 8 o 9 dígitos.";
    }
  
    if (!formData.email) newErrors.email = "Correo Electrónico es requerido.";
    if (!formData.cellphone) newErrors.cellphone = "Teléfono es requerido.";
    if (!formData.date) newErrors.date = "Fecha es requerida.";
    if (!formData.time) newErrors.time = "Horario es requerido.";
  
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const API_URL = process.env.REACT_APP_API_URL || "https://www.fit-instinct.com/api";

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      setMessage("Por favor completa todos los campos requeridos.");
      return;
    }

    try {
      const response = await fetch(`${API_URL}/users/register`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      const result = await response.json();

      if (response.ok) {
        setMessage("¡Registro exitoso! Revisa tu correo para más detalles.");
        setFormData({
          name: "",
          lastname: "",
          dui: "",
          email: "",
          cellphone: "",
          date: "",
          time: "",
        });
      } else {
        setMessage(result.error || "Ocurrió un error. Por favor intenta nuevamente.");
      }
    } catch (error) {
      console.error("Error registering user:", error);
      setMessage("Error conectando al servidor. Por favor, inténtalo nuevamente.");
    }
  };

  return (
    <div>
      <NavbarComponent />
      <div style={styles.backgroundSection}>
        <div style={styles.formContainer}>
          <h1 style={styles.mainTitle}>Regístrate para tu clase gratis</h1>
          <p style={styles.infoText}>
            Las clases gratis pueden usarse para YOGA o clases regulares.
          </p>
          <form onSubmit={handleSubmit}>
            <div style={styles.sectionContainer}>
              <h2 style={styles.sectionTitle}>Tu Información</h2>
              <input
                type="text"
                name="name"
                placeholder="Nombre"
                value={formData.name}
                onChange={handleChange}
                style={styles.input}
              />
              {errors.name && <p style={styles.errorText}>{errors.name}</p>}
              <input
                type="text"
                name="lastname"
                placeholder="Apellido"
                value={formData.lastname}
                onChange={handleChange}
                style={styles.input}
              />
              {errors.lastname && <p style={styles.errorText}>{errors.lastname}</p>}
              <input
                type="text"
                name="dui"
                placeholder="DUI"
                value={formData.dui}
                onChange={handleChange}
                style={styles.input}
              />
              {errors.dui && <p style={styles.errorText}>{errors.dui}</p>}
              <input
                type="email"
                name="email"
                placeholder="Correo Electrónico"
                value={formData.email}
                onChange={handleChange}
                style={styles.input}
              />
              {errors.email && <p style={styles.errorText}>{errors.email}</p>}
              <div style={styles.phoneInputContainer}>
                <PhoneInput
                  defaultCountry="SV"
                  value={formData.cellphone}
                  onChange={(value) => setFormData({ ...formData, cellphone: value })}
                  style={styles.phoneInput}
                  placeholder="Teléfono"
                />
                {errors.cellphone && <p style={styles.errorText}>{errors.cellphone}</p>}
              </div>
            </div>

            <div style={styles.sectionContainer}>
              <h2 style={styles.sectionTitle}>Escoge la fecha y horario para tu clase</h2>
              <p style={styles.infoText}>Selecciona la fecha</p>
              <input
                type="date"
                name="date"
                value={formData.date}
                onChange={handleChange}
                style={styles.input}
              />
              {errors.date && <p style={styles.errorText}>{errors.date}</p>}
              <p style={styles.infoText}>Selecciona un horario</p>
                <select
                  name="time"
                  value={formData.time}
                  onChange={handleChange}
                  style={styles.select}
                >
                  <option value="">Horario</option>
                  {availableTimes.map((time) => (
                    <option value={time} key={time}>
                      {time}
                    </option>
                  ))}
                </select>
              {errors.time && <p style={styles.errorText}>{errors.time}</p>}
            </div>
            <button
              type="submit"
              style={isHovered ? { ...styles.button, ...styles.buttonHover } : styles.button}
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            >
              Enviar
            </button>
          </form>
          {message && <p>{message}</p>}
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Register;