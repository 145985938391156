import React from "react";
import NavbarComponent from "./NavbarComponent";
import Footer from "./Footer";
import SpecialOfferFeb2025 from "./SpecialOfferFeb2025";

function SpecialOfferPage() {
  const styles = {
    pageContainer: {
      margin: "0",
      padding: "0",
    },
    contentContainer: {
      marginTop: "60px", // Space below the navbar
    },
  };

  return (
    <div style={styles.pageContainer}>
      {/* Navbar */}
      <NavbarComponent showSpecialOffer={false} />

      {/* Special Offer Content */}
      <div style={styles.contentContainer}>
        <SpecialOfferFeb2025 />
      </div>

      {/* Footer */}
      <Footer />
    </div>
  );
}

export default SpecialOfferPage;