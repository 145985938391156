import React, { useState, useEffect } from "react";

function Membresias() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [isRecurring, setIsRecurring] = useState(true);
  const [selectedMembership, setSelectedMembership] = useState(null);
  const [hoveredButtonIndex, setHoveredButtonIndex] = useState(null);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const togglePaymentMode = () => setIsRecurring(!isRecurring);

  const memberships = [
    {
      title: "Elite",
      subtitle: "Acceso ilimitado",
      duration: "Válido por un mes",
      details: [
        "Acceso ilimitado a todas las clases grupales.",
        "Válido en cualquier horario.",
        "Acceso a nuestra app.",
        "Resúmenes de rendimiento enviados a tu correo.",
        "¡Participa en los desafíos de FitInstinct!",
        "Acceso al seguimiento de datos biométricos.",
        "Descuento con pago recurrente cada mes (Renovación automática).",
        "Esta membresía puede ser utilizada para clases de Yoga.",
      ],
      recurringPrice: "$112 al mes",
      oneTimePrice: "$125",
      recurringLink: "https://pay.n1co.shop/pl/E5pAbfwvy",
      oneTimeLink: "https://pay.n1co.shop/pl/xWBQZtjGQ",
    },
    {
      title: "High Level",
      subtitle: "Incluye 16 clases",
      duration: "Válidas por un mes",
      details: [
        "16 clases válidas por un mes.",
        "Válido en cualquier horario.",
        "Acceso a nuestra app.",
        "Resúmenes de rendimiento enviados a tu correo.",
        "¡Participa en los desafíos de FitInstinct!",
        "Descuento con pago recurrente cada mes (Renovación automática).",
        "Esta membresía puede ser utilizada para clases de Yoga.",
      ],
      recurringPrice: "$105 al mes",
      oneTimePrice: "$115",
      recurringLink: "https://pay.n1co.shop/pl/k59xqfaGL",
      oneTimeLink: "https://pay.n1co.shop/pl/yQZYOhLpE",
    },
    {
      title: "Athlete",
      subtitle: "Incluye 12 clases",
      duration: "Válidas por un mes",
      details: [
        "12 clases válidas por un mes.",
        "Válido en cualquier horario.",
        "Acceso a nuestra app.",
        "Resúmenes de rendimiento enviados a tu correo.",
        "¡Participa en los desafíos de FitInstinct!",
        "Descuento con pago recurrente cada mes (Renovación automática).",
        "Esta membresía puede ser utilizada para clases de Yoga.",
      ],
      recurringPrice: "$86 al mes",
      oneTimePrice: "$96",
      recurringLink: "https://pay.n1co.shop/pl/Y59mdFwa1",
      oneTimeLink: "https://pay.n1co.shop/pl/X5BvrsODV",
    },
    {
      title: "Casual",
      subtitle: "Incluye 8 clases",
      duration: "Válidas por un mes",
      details: [
        "8 clases válidas por un mes.",
        "Válido en cualquier horario.",
        "Acceso a nuestra app.",
        "Resúmenes de rendimiento enviados a tu correo.",
        "¡Participa en los desafíos de FitInstinct!",
        "Descuento con pago recurrente cada mes (Renovación automática).",
        "Esta membresía puede ser utilizada para clases de Yoga.",
      ],
      recurringPrice: "$61 al mes",
      oneTimePrice: "$68",
      recurringLink: "https://pay.n1co.shop/pl/dr9mGiw9p",
      oneTimeLink: "https://pay.n1co.shop/pl/4pezQhVX1",
    },
    {
      title: "4 Classes",
      subtitle: "Incluye 4 clases",
      duration: "Válidas por 4 semanas",
      details: [
        "4 clases válidas por 4 semanas.",
        "Válido en cualquier horario.",
        "Acceso a nuestra app.",
        "Resúmenes de rendimiento enviados a tu correo.",
        "¡Participa en los desafíos de FitInstinct!",
        "Esta membresía puede ser utilizada para clases de Yoga.",
      ],
      price: "$45",
      link: "https://pay.n1co.shop/pl/8K1LxUOxj",
    },
    {
      title: "One Class",
      subtitle: "Incluye 1 clase",
      duration: "Válida por 4 semanas",
      details: [
        "1 clase válida por 4 semanas.",
        "Válido en cualquier horario.",
        "Acceso a nuestra app por 4 semanas.",
        "Resúmenes de rendimiento enviados a tu correo.",
        "¡Participa en los desafíos de FitInstinct!",
        "Esta membresía puede ser utilizada para clases de Yoga.",
      ],
      price: "$12",
      link: "https://pay.n1co.shop/pl/pK29AS2PL",
    },
    {
      title: "Kids",
      subtitle: "3 clases por semana",
      duration: "Válidas por un mes",
      details: [
        "Solo menores de 18 años",
        "3 clases por semana (Lunes a Viernes).",
        "Clases especiales para jovenes y niños.",
        "Válido durante un mes.",
      ],
      recurringPrice: "$65 al mes",
      oneTimePrice: "$70",
      recurringLink: "https://pay.n1co.shop/pl/LxPm4CPdR",
      oneTimeLink: "https://pay.n1co.shop/pl/Z59mESP2Z",
    },
    {
      title: "Family Package (4 Personas)",
      subtitle: "12 clases por miembro",
      duration: "Válidas por un mes",
      details: [
        "12 clases por cada miembro (4 miembros).",
        "Válido en cualquier horario.",
        "Acceso a nuestra app.",
        "Resúmenes de rendimiento enviados a tu correo.",
        "¡Participa en los desafíos de FitInstinct!",
        "Descuento con pago recurrente cada mes (Renovación automática).",
        "Esta membresía puede ser utilizada para clases de Yoga.",
      ],
      recurringPrice: "$300 al mes",
      oneTimePrice: "$325",
      recurringLink: "https://pay.n1co.shop/pl/P9rmMcvrk",
      oneTimeLink: "https://pay.n1co.shop/pl/l5mY9cdGA",
    },
    {
      title: "Family Package (3 Personas)",
      subtitle: "12 clases por miembro",
      duration: "Válidas por un mes",
      details: [
        "12 clases por cada miembro (3 miembros).",
        "Válido en cualquier horario.",
        "Acceso a nuestra app.",
        "Resúmenes de rendimiento enviados a tu correo.",
        "¡Participa en los desafíos de FitInstinct!",
        "Descuento con pago recurrente cada mes (Renovación automática).",
        "Esta membresía puede ser utilizada para clases de Yoga.",
      ],
      recurringPrice: "$235 al mes",
      oneTimePrice: "$250",
      recurringLink: "https://pay.n1co.shop/pl/DdpkXFYv5",
      oneTimeLink: "https://pay.n1co.shop/pl/3Ya1eTaY5",
    },
  ];

  const styles = {
    membershipsSection: {
      padding: "80px 20px 50px 20px",
      backgroundImage: "url('/Comprimidas/bluegirl.jpg')",
      backgroundSize: "cover",
      backgroundPosition: isMobile ? "80% center" : "center",
      backgroundRepeat: "no-repeat",
      textAlign: "center",
      color: "#ffffff",
    },
    toggleContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      gap: "10px",
      marginBottom: "20px",
    },
    toggleText: {
      fontFamily: "Montserrat, sans-serif",
      fontSize: "1rem",
      fontWeight: "bold",
      color: "#ffffff",
    },
    toggleButton: {
      position: "relative",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      width: "90px",
      height: "30px",
      backgroundColor: isRecurring ? "#1466C3" : "#cccccc",
      borderRadius: "20px",
      padding: "5px",
      cursor: "pointer",
      transition: "background-color 0.3s ease",
    },
    toggleCircle: {
      position: "absolute",
      top: "5px",
      left: isRecurring ? "5px" : "65px",
      width: "20px",
      height: "20px",
      backgroundColor: "#ffffff",
      borderRadius: "50%",
      transition: "left 0.3s ease",
    },
    membershipsContainer: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "center",
      gap: "15px",
      maxWidth: "1000px",
      margin: "0 auto",
    },
    membershipCard: {
      backgroundColor: "rgba(255, 255, 255, 0.1)",
      borderRadius: "10px",
      padding: "20px",
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.5)",
      textAlign: "center",
      flex: "1 1 250px",
      maxWidth: "320px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      minHeight: "300px",
    },
    membershipTitle: {
      fontFamily: "Gendy, sans-serif",
      fontSize: "1.3rem",
      fontWeight: "bold",
      marginBottom: "5px",
    },
    detailsLink: {
      fontFamily: "Montserrat, sans-serif",
      fontSize: "0.9rem",
      fontWeight: "bold",
      textDecoration: "underline",
      color: "#ffffff",
      marginBottom: "10px",
      cursor: "pointer",
    },
    modal: {
      position: "fixed",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      backgroundColor: "#ffffff",
      padding: "20px",
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.5)",
      zIndex: 1000,
      borderRadius: "10px",
      maxWidth: isMobile ? "90%" : "400px", // Adjusted for mobile screens
      width: isMobile ? "90%" : "auto", // Ensures modal takes more width on mobile
      textAlign: "left",
      color: "#333",
    },
    overlay: {
      position: "fixed",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      zIndex: 999,
    },
    modalTitle: {
      fontFamily: "Gendy, sans-serif",
      fontSize: "1.5rem",
      fontWeight: "bold",
      marginBottom: "15px",
    },
    modalDetails: {
      fontFamily: "Montserrat, sans-serif",
      fontSize: "1rem",
      lineHeight: "1.5",
    },
    textContainer: {
      textAlign: "center",
    },
    text: {
      fontFamily: "Montserrat, sans-serif",
      fontSize: "0.9rem",
      marginBottom: "5px",
      color: "#ffffff",
    },
    price: {
      fontFamily: "Gendy, sans-serif",
      fontSize: "1.3rem",
      fontWeight: "bold",
      marginBottom: "30px",
      color: "#ffffff",
    },
    button: {
      fontFamily: "Montserrat, sans-serif",
      backgroundColor: "#1466C3",
      fontWeight: "bold",
      textTransform: "uppercase",
      color: "#ffffff",
      border: "none",
      padding: "10px 15px",
      borderRadius: "5px",
      cursor: "pointer",
      transition: "all 0.3s ease-in-out",
      alignSelf: "center",
    },
    buttonHover: {
      transform: "scale(1.1)",
      backgroundColor: "#1259A8",
      transition: "all 0.3s ease-in-out",
    },
  };

  return (
    <section style={styles.membershipsSection}>
      <h2 style={{ fontFamily: "Gendy, sans-serif", fontSize: "2.5rem" }}>
        Membresías
      </h2>
      <p style={{ marginTop: "10px", color: "#ffffff", fontFamily: "Montserrat, sans-serif", textAlign: "center", fontSize: "1.1rem" }}>
        ¡Hoy todas nuestras membresías pueden ser utilizadas para asistir a clases de Yoga también! Revisa los paquetes familiares y las membresías para niños.
      </p>
      <div style={styles.toggleContainer}>
      <span style={styles.toggleText}>Renovación automática</span>
        <div style={styles.toggleButton} onClick={togglePaymentMode}>
          <div style={styles.toggleCircle}></div>
        </div>
        <span style={styles.toggleText}>Pago único</span>
      </div>

      <div style={styles.membershipsContainer}>
        {memberships.map((membership, index) => (
          <div key={index} style={styles.membershipCard}>
            <h3 style={styles.membershipTitle}>{membership.title}</h3>
            <p
              style={styles.detailsLink}
              onClick={() => setSelectedMembership(membership)}
            >
              Más Información
            </p>
            <p style={styles.text}>{membership.subtitle}</p>
            <p style={styles.text}>{membership.duration}</p>
            <p style={styles.price}>
              {membership.price ||
                (isRecurring ? membership.recurringPrice : membership.oneTimePrice)}
            </p>
            <button
              style={
                hoveredButtonIndex === index
                  ? { ...styles.button, ...styles.buttonHover }
                  : styles.button
              }
              onMouseEnter={() => setHoveredButtonIndex(index)}
              onMouseLeave={() => setHoveredButtonIndex(null)}
              onClick={() =>
                (window.location.href =
                  membership.link ||
                  (isRecurring ? membership.recurringLink : membership.oneTimeLink))
              }
            >
              Comprar
            </button>
          </div>
        ))}
      </div>

      {selectedMembership && (
        <>
          <div style={styles.overlay} onClick={() => setSelectedMembership(null)}></div>
          <div style={styles.modal}>
            <h3 style={{ ...styles.modalTitle, textAlign: "center" }}>{selectedMembership.title}</h3>
            {selectedMembership.details && selectedMembership.details.length > 0 ? (
              <ul style={styles.modalDetails}>
                {selectedMembership.details.map((detail, idx) => (
                  <li key={idx}>{detail}</li>
                ))}
              </ul>
            ) : (
              <p>No details available for this membership.</p>
            )}
            <div style={{ textAlign: "center", marginTop: "20px" }}>
              <button
                style={styles.button}
                onClick={() =>
                  (window.location.href =
                    selectedMembership.link ||
                    (isRecurring
                      ? selectedMembership.recurringLink
                      : selectedMembership.oneTimeLink))
                }
              >
                Comprar
              </button>
            </div>
          </div>
        </>
      )}
    </section>
  );
}

export default Membresias;