import React, { useState } from "react";

function SpecialOfferFeb2025Explanation() {
  const [isVisible, setIsVisible] = useState(true);

  const styles = {
    offer: {
      backgroundColor: "#FFFFFF", // White background
      color: "#000000", // Black text
      padding: "20px",
      position: "sticky",
      top: "60px", // Positioned below the navbar
      zIndex: 999,
      display: isVisible ? "flex" : "none",
      justifyContent: "space-between",
      alignItems: "center",
      fontFamily: "Montserrat, sans-serif",
      fontSize: "1rem",
      fontWeight: "500",
      boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)", // Subtle shadow for visibility
    },
    text: {
      margin: 0,
      fontWeight: "bold",
      flex: 1,
    },
    button: {
      fontFamily: "Montserrat, sans-serif",
      fontWeight: "bold",
      fontSize: "1rem",
      backgroundColor: "#1466C3",
      color: "#FFFFFF",
      border: "none",
      padding: "10px 20px",
      borderRadius: "5px",
      cursor: "pointer",
      transition: "background-color 0.3s ease-in-out",
      marginRight: "10px",
    },
    buttonHover: {
      backgroundColor: "#0D4E91",
    },
    closeButton: {
      fontSize: "1.5rem",
      fontWeight: "bold",
      cursor: "pointer",
      background: "none",
      border: "none",
      color: "#000",
      marginLeft: "15px",
    },
  };

  if (!isVisible) return null;

  return (
    <div style={styles.offer}>
      <p style={styles.text}>
        ¡<strong>Oferta Especial de Febrero💖!</strong> Para todas las membresías individuales, si te inscribes con un amigo u amiga, obtienen un{" "}
        <strong>15% de descuento adicional para ambos.</strong>
      </p>
      <button
        style={styles.button}
        onMouseOver={(e) =>
          (e.currentTarget.style.backgroundColor = styles.buttonHover.backgroundColor)
        }
        onMouseOut={(e) =>
          (e.currentTarget.style.backgroundColor = styles.button.backgroundColor)
        }
        onClick={() => {
          window.location.href = "/special-offer";
        }}
      >
        Oferta
      </button>
      <button
        style={styles.closeButton}
        onClick={() => setIsVisible(false)}
      >
        ✖
      </button>
    </div>
  );
}

export default SpecialOfferFeb2025Explanation;