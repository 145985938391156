import React from "react";
import { useNavigate } from "react-router-dom";

function Workout() {
  const navigate = useNavigate();

  const styles = {
    tecnologiaSection: {
      display: "flex",
      flexDirection: "row", // Side-by-side layout for larger screens
      justifyContent: "flex-end",
      alignItems: "center",
      padding: "50px",
      backgroundImage: "url('/Comprimidas/techbackground2.jpg')", // Replace with your background image path
      backgroundSize: "cover",
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      minHeight: "90vh",
      position: "relative",
    },
    contentBox: {
      backgroundColor: "rgba(0, 0, 0, 0.6)", // Semi-transparent background
      padding: "30px",
      borderRadius: "10px",
      color: "#FFFFFF",
      maxWidth: "500px",
      textAlign: "center", // Center text for mobile
      margin: "0 auto", // Center horizontally
    },
    title: {
      fontFamily: "Gendy, sans-serif",
      fontSize: "2rem",
      fontWeight: "bold",
      marginBottom: "20px",
      color: "#FFFFFF",
    },
    content: {
      fontFamily: "Montserrat, sans-serif",
      fontSize: "1rem",
      lineHeight: "1.5",
      marginBottom: "20px",
      color: "#FFFFFF",
    },
    button: {
      fontFamily: "Montserrat, sans-serif",
      fontWeight: "bold",
      textTransform: "uppercase",
      backgroundColor: "#1466C3",
      borderColor: "#1466C3",
      padding: "0.75rem 1.5rem",
      color: "#ffffff",
      border: "none",
      borderRadius: "5px",
      cursor: "pointer",
      transition: "all 0.3s ease-in-out",
    },
    buttonHover: {
      backgroundColor: "#0d4e91",
      transform: "scale(1.05)",
    },
    responsiveContainer: {
      flexDirection: "column", // Stack items vertically on smaller screens
      justifyContent: "center",
      alignItems: "center",
      textAlign: "center",
    },
  };

  // Use a function to dynamically apply styles for responsiveness
  const isMobile = window.innerWidth < 768;

  return (
    <section
      id="workout"
      style={{
        ...styles.tecnologiaSection,
        ...(isMobile ? styles.responsiveContainer : {}),
      }}
    >
      {/* Text Section */}
      <div style={styles.contentBox}>
        <h2 style={styles.title}>Nuestros Entrenamientos</h2>
        <p style={styles.content}>
          En FitInstinct, integramos tecnología avanzada en todos nuestros
          entrenamientos para garantizar que obtengas resultados efectivos y
          medibles. Descubre cómo transformamos tu fitness.
        </p>
        <button
          style={styles.button}
          onClick={() => navigate("/workout")}
          onMouseOver={(e) => {
            e.currentTarget.style.backgroundColor = styles.buttonHover.backgroundColor;
            e.currentTarget.style.transform = styles.buttonHover.transform;
          }}
          onMouseOut={(e) => {
            e.currentTarget.style.backgroundColor = styles.button.backgroundColor;
            e.currentTarget.style.transform = "scale(1)";
          }}
        >
          Más Información
        </button>
      </div>
    </section>
  );
}

export default Workout;