import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";

function Entrenadores() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [currentCoach, setCurrentCoach] = useState(0);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const coaches = [
    {
      name: "Marcos Rivera",
      position: "Entrenador Jefe",
      description:
        "Ex atleta de alto rendimiento con más de 8 años de experiencia como entrenador personal y grupal en entrenamientos funcionales, adaptados para todas las edades.",
      image: "/Comprimidas/marcos.jpg",
    },
    {
      name: "Alejandra Santamaria",
      position: "Entrenadora",
      description:
        "Con más de 14 años de experiencia en diferentes deportes competitivos, graduada de entrenadora personal y gestión de centros fitness en Guatemala.",
      image: "/Comprimidas/ale.jpg",
    },
    {
      name: "Jimmy Aguilar",
      position: "Entrenador",
      description:
        "Maestro de boxeo funcional con años de experiencia. Jimmy se enfoca en mejorar la fuerza, resistencia y coordinación. Dirige clases dinámicas para grupos de todos los niveles, adaptando entrenamientos para maximizar resultados.",
      image: "/Comprimidas/jimmy.jpg",
    },
    {
      name: "Jorge Flores",
      position: "Entrenador",
      description:
        "Atleta con más de 4 años de experiencia en powerlifting y crossfit, especializado en guiar a las personas hacia su máximo rendimiento y en ayudarlas a alcanzar la mejor versión de sí mismas a través de un entrenamiento integral y eficaz.",
      image: "/Comprimidas/jorge.jpg",
    },
    {
      name: "Majo Guzmán",
      position: "Entrenadora Yoga",
      description:
      "Especialista en yoga certificada por Yoga Alliance, apasionada por ayudar a las personas a encontrar el equilibrio entre el estado físico y mental. Majo combina técnicas avanzadas de yoga con prácticas personalizadas para promover el bienestar integral.",
      image: "/Comprimidas/majo.jpg",
    },
  ];

  const handlePrev = () => {
    setCurrentCoach((prev) => (prev === 0 ? coaches.length - 1 : prev - 1));
  };

  const handleNext = () => {
    setCurrentCoach((prev) => (prev === coaches.length - 1 ? 0 : prev + 1));
  };

  const styles = {
    entrenadoresSection: {
      padding: "50px",
      backgroundImage: "url('/Comprimidas/headerbackground.jpg')",
      backgroundSize: "cover",
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      color: "#ffffff",
      position: "relative",
    },
    title: {
      fontFamily: "Gendy, sans-serif",
      fontSize: "2.5rem",
      fontWeight: "bold",
      marginBottom: "30px",
      textAlign: "center",
    },
    contentWrapper: {
      position: "relative",
      width: "100%",
      maxWidth: "1200px",
      margin: "0 auto",
      overflow: "hidden",
      minHeight: "500px", // Ensures a fixed height for all content
    },
    coachContainer: {
      display: "flex",
      flexDirection: isMobile ? "column" : "row",
      alignItems: "center",
      justifyContent: "center",
      gap: "20px",
    },
    coachImage: {
      width: isMobile ? "80%" : "300px",
      height: "auto",
      borderRadius: "15px",
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.5)",
    },
    coachDetails: {
      textAlign: "center",
      maxWidth: isMobile ? "90%" : "400px",
    },
    coachName: {
      fontFamily: "Gendy, sans-serif",
      fontSize: "1.8rem",
      fontWeight: "bold",
      marginBottom: "10px",
      color: "#ffffff",
    },
    coachPosition: {
      fontFamily: "Montserrat, sans-serif",
      fontSize: "1.2rem",
      fontWeight: "bold",
      color: "#1466C3",
      marginBottom: "15px",
    },
    coachDescription: {
      fontFamily: "Montserrat, sans-serif",
      fontSize: "1rem",
      lineHeight: "1.5",
      color: "#ffffff",
    },
    arrow: {
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      fontSize: "3rem",
      color: "#ffffff",
      cursor: "pointer",
      zIndex: 10,
    },
    leftArrow: {
      left: isMobile ? "0px" : "10px", // Closer to the edge on mobile
    },
    rightArrow: {
      right: isMobile ? "0px" : "10px", // Closer to the edge on mobile
    },
  };

  return (
    <section id="entrenadores" style={styles.entrenadoresSection}>
      <h2 style={styles.title}>Entrenadores</h2>
      <div style={styles.contentWrapper}>
        <div
          style={{
            ...styles.arrow,
            ...(isMobile ? { left: "0px", fontSize: "3rem" } : styles.leftArrow),
          }}
          onClick={handlePrev}
        >
          &larr;
        </div>
        <div
          style={{
            ...styles.arrow,
            ...(isMobile ? { right: "0px", fontSize: "3rem" } : styles.rightArrow),
          }}
          onClick={handleNext}
        >
          &rarr;
        </div>
        <AnimatePresence mode="wait">
          <motion.div
            key={currentCoach}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.7 }}
            style={styles.coachContainer}
          >
            <img
              src={coaches[currentCoach].image}
              alt={coaches[currentCoach].name}
              style={styles.coachImage}
            />
            <div style={styles.coachDetails}>
              <h3 style={styles.coachName}>{coaches[currentCoach].name}</h3>
              <p style={styles.coachPosition}>{coaches[currentCoach].position}</p>
              <p style={styles.coachDescription}>{coaches[currentCoach].description}</p>
            </div>
          </motion.div>
        </AnimatePresence>
      </div>
    </section>
  );
}

export default Entrenadores;