import React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";

function Header() {
  const navigate = useNavigate();

  const styles = {
    header: {
      backgroundImage: "url('/Comprimidas/bluegirlbackground.jpg')",
      height: "100vh",
      backgroundSize: "cover",
      backgroundPosition: "center",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      color: "#FFFFFF",
      textAlign: "center",
      paddingTop: "100px", // Adjusted to avoid overlap with SpecialOffer
    },
    title: {
      fontFamily: "Gendy, sans-serif",
      fontSize: "3rem",
      fontWeight: "bold",
      marginBottom: "1rem",
    },
    subtitle: {
      fontFamily: "Montserrat, sans-serif",
      fontWeight: "bold",
      fontSize: "1.5rem",
      marginBottom: "2rem",
    },
    button: {
      fontFamily: "Montserrat, sans-serif",
      fontWeight: "500",
      fontSize: "1rem",
      backgroundColor: "#1466C3",
      borderColor: "#1466C3",
      textTransform: "uppercase",
      color: "#ffffff",
      border: "none",
      padding: "0.75rem 1.5rem",
      borderRadius: "5px",
      cursor: "pointer",
      transition: "all 0.3s ease-in-out", // Smooth transition
    },
    buttonHover: {
      backgroundColor: "#0d4e91", // Slightly darker blue
      transform: "scale(1.05)", // Slight enlargement
    },
  };

  return (
    <header style={styles.header}>
      {/* Main Header Content */}
      <div>
        <h1 style={styles.title}>
          Entrena con tecnología avanzada y alcanza tus objetivos.
        </h1>
        <p style={styles.subtitle}>Obten tu clase gratis hoy!</p>
        <div>
          <Button
            onClick={() => navigate("/register")}
            style={styles.button}
            onMouseOver={(e) => {
              e.currentTarget.style.backgroundColor =
                styles.buttonHover.backgroundColor;
              e.currentTarget.style.transform = styles.buttonHover.transform;
            }}
            onMouseOut={(e) => {
              e.currentTarget.style.backgroundColor =
                styles.button.backgroundColor;
              e.currentTarget.style.transform = "scale(1)";
            }}
          >
            Clase Gratis
          </Button>
        </div>
      </div>
    </header>
  );
}

export default Header;