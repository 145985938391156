import React from "react";

function SensorPage() {
  const styles = {
    sensorSection: {
      padding: "60px 20px",
      background: "linear-gradient(135deg, #f8f9fa, #e9ecef)", // Subtle gradient
      color: "#111111",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      textAlign: "center",
    },
    textContainer: {
      maxWidth: "700px",
      marginBottom: "20px", // Reduced spacing
      fontFamily: "Montserrat, sans-serif",
    },
    sensorTitle: {
      fontFamily: "Gendy, sans-serif",
      fontSize: "2.5rem",
      fontWeight: "bold",
      marginBottom: "10px", // Reduced spacing
    },
    sensorContent: {
      fontSize: "1.2rem",
      lineHeight: "1.8",
      color: "#555555",
    },
    priceContainer: {
      marginTop: "10px",
      marginBottom: "20px",
    },
    price: {
      fontFamily: "Gendy, sans-serif",
      fontSize: "1.8rem",
      fontWeight: "bold",
      color: "#111111",
    },
    imageContainer: {
      display: "flex",
      justifyContent: "center",
      marginBottom: "30px", // Slightly reduced spacing
    },
    sensorImage: {
      width: "100%",
      maxWidth: "350px",
      height: "auto",
      borderRadius: "10px",
      boxShadow: "0 4px 15px rgba(0, 0, 0, 0.1)",
    },
    buttonContainer: {
      marginTop: "20px",
    },
    button: {
      fontFamily: "Montserrat, sans-serif",
      fontSize: "1rem",
      fontWeight: "bold",
      backgroundColor: "#1466C3",
      color: "#ffffff",
      border: "none",
      padding: "15px 40px", // Rectangular button with wider padding
      borderRadius: "10px", // Rounded corners
      cursor: "pointer",
      textTransform: "uppercase",
      boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
      transition: "all 0.3s ease-in-out",
    },
    buttonHover: {
      backgroundColor: "#0d4e91",
      transform: "scale(1.05)",
    },
  };

  const handleMouseOver = (e) => {
    e.currentTarget.style.backgroundColor = styles.buttonHover.backgroundColor;
    e.currentTarget.style.transform = styles.buttonHover.transform;
  };

  const handleMouseOut = (e) => {
    e.currentTarget.style.backgroundColor = styles.button.backgroundColor;
    e.currentTarget.style.transform = "scale(1)";
  };

  return (
    <div style={styles.sensorSection}>
      {/* Text Section */}
      <div style={styles.textContainer}>
        <h2 style={styles.sensorTitle}>Sensor</h2>
        <p style={styles.sensorContent}>
          Con nuestro sensor de frecuencia cardíaca, monitorea tu entrenamiento
          en tiempo real. Los datos recopilados permiten personalizar cada
          sesión para maximizar tus resultados.
        </p>
      </div>

      {/* Price Section */}
      <div style={styles.priceContainer}>
        <p style={styles.price}>Solo por $65</p>
      </div>

      {/* Image Section */}
      <div style={styles.imageContainer}>
        <img
          src="/Comprimidas/Sensor.jpg" // Replace with your sensor image
          alt="Sensor"
          style={styles.sensorImage}
        />
      </div>

      {/* Button Section */}
      <div style={styles.buttonContainer}>
        <button
          style={styles.button}
          onMouseOver={handleMouseOver}
          onMouseOut={handleMouseOut}
          onClick={() =>
            (window.location.href = "https://pay.n1co.shop/pl/MV80xF1wp")
          }
        >
          Comprar Sensor
        </button>
      </div>
    </div>
  );
}

export default SensorPage;