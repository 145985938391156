import React, { useState } from "react";

function SpecialOfferFeb2025() {
  const [hoveredButton, setHoveredButton] = useState(null);

  const styles = {
    offerSection: {
      minHeight: "500px",
      padding: "60px 20px",
      marginTop: "60px",
      backgroundColor: "#1466C3",
      color: "#FFFFFF",
      textAlign: "center",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    title: {
      fontFamily: "Gendy, sans-serif",
      fontSize: "2.5rem",
      fontWeight: "bold",
      marginBottom: "20px",
    },
    description: {
      fontFamily: "Montserrat, sans-serif",
      fontSize: "1.2rem",
      fontWeight: "500",
      lineHeight: "1.5",
      maxWidth: "800px",
      margin: "0 auto",
      marginBottom: "30px",
    },
    membershipsContainer: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "center",
      gap: "20px",
      width: "100%",
      maxWidth: "1200px",
    },
    membership: {
      flex: "1 1 calc(33% - 20px)", // Makes 3 items per row
      backgroundColor: "#FFFFFF",
      color: "#1466C3",
      borderRadius: "10px",
      padding: "20px",
      textAlign: "center",
      boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
      transition: "transform 0.2s ease",
    },
    membershipHovered: {
      transform: "scale(1.05)",
    },
    membershipName: {
      fontFamily: "Montserrat, sans-serif",
      fontSize: "1.5rem",
      fontWeight: "bold",
      marginBottom: "10px",
    },
    price: {
      fontFamily: "Montserrat, sans-serif",
      fontSize: "1.3rem",
      fontWeight: "bold",
    },
    button: {
      marginTop: "15px",
      fontFamily: "Montserrat, sans-serif",
      fontSize: "1rem",
      fontWeight: "bold",
      backgroundColor: "#1466C3",
      color: "#FFFFFF",
      border: "none",
      padding: "10px 20px",
      borderRadius: "5px",
      cursor: "pointer",
      textTransform: "uppercase",
      transition: "background-color 0.3s ease",
    },
    buttonHovered: {
      backgroundColor: "#1259A8",
    },
  };

  const handleMouseEnter = (buttonId) => {
    setHoveredButton(buttonId);
  };

  const handleMouseLeave = () => {
    setHoveredButton(null);
  };

  return (
    <div style={styles.offerSection}>
      <h2 style={styles.title}>¡Oferta Especial de Febrero! 💖</h2>
      <p style={styles.description}>
        Este febrero, obtén un <strong>15% de descuento adicional</strong> al inscribirte junto con un amigo. ¡El precio indicado es para dos membresías! 🌟
      </p>

      <div style={styles.membershipsContainer}>
        {[
          {
            name: "Membresía Elite x2",
            price: "Solo $212/mes (antes $250)",
            link: "https://pay.n1co.shop/pl/28Lv9hx34",
            id: "elite",
          },
          {
            name: "Membresía High Level x2",
            price: "Solo $196/mes (antes $230)",
            link: "https://pay.n1co.shop/pl/KZYMMfMLz",
            id: "highLevel",
          },
          {
            name: "Membresía Athlete x2",
            price: "Solo $163/mes (antes $192)",
            link: "https://pay.n1co.shop/pl/QweMzcvKV",
            id: "athlete",
          },
          {
            name: "Membresía Casual x2",
            price: "Solo $116/mes (antes $136)",
            link: "https://pay.n1co.shop/pl/wW0q7sDPy",
            id: "casual",
          },
          {
            name: "4 Clases x2",
            price: "Solo $77 (antes $90)",
            link: "https://pay.n1co.shop/pl/xWyOwtjGQ",
            id: "fourClasses",
          },
          {
            name: "1 Clase x2",
            price: "Solo $21 (antes $24)",
            link: "https://pay.n1co.shop/pl/b5XLAHMEQ",
            id: "oneClass",
          },
        ].map((membership) => (
          <div
            key={membership.id}
            style={
              hoveredButton === membership.id
                ? { ...styles.membership, ...styles.membershipHovered }
                : styles.membership
            }
            onMouseEnter={() => handleMouseEnter(membership.id)}
            onMouseLeave={handleMouseLeave}
          >
            <h3 style={styles.membershipName}>{membership.name}</h3>
            <p style={styles.price}>{membership.price}</p>
            <button
              style={
                hoveredButton === membership.id
                  ? { ...styles.button, ...styles.buttonHovered }
                  : styles.button
              }
              onClick={() => (window.location.href = membership.link)}
            >
              Comprar Paquete para Dos
            </button>
          </div>
        ))}
      </div>
    </div>
  );
}

export default SpecialOfferFeb2025;