import React from "react";
import { useNavigate } from "react-router-dom";

function YogaClasses() {
  const navigate = useNavigate();

  const styles = {
    yogaSection: {
      display: "flex",
      flexDirection: "row", // Side-by-side layout for larger screens
      justifyContent: "space-between",
      alignItems: "center",
      padding: "50px",
      backgroundImage: "url('/Comprimidas/yoga2.jpg')", // Replace with your background image path
      backgroundSize: "cover",
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      minHeight: "90vh",
      position: "relative",
    },
    contentBox: {
      backgroundColor: "rgba(0, 0, 0, 0.4)", // Semi-transparent background
      padding: "30px",
      borderRadius: "10px",
      color: "#FFFFFF",
      maxWidth: "500px",
      textAlign: "center", // Center text for mobile
      margin: "0 auto", // Center horizontally on mobile
    },
    title: {
      fontFamily: "Gendy, sans-serif",
      fontSize: "2.5rem",
      fontWeight: "bold",
      marginBottom: "20px",
      color: "#FFFFFF",
    },
    content: {
      fontFamily: "Montserrat, sans-serif",
      fontSize: "1rem",
      lineHeight: "1.5",
      marginBottom: "20px",
      color: "#FFFFFF",
    },
    button: {
      fontFamily: "Montserrat, sans-serif",
      fontWeight: "bold",
      textTransform: "uppercase",
      backgroundColor: "#1466C3",
      borderColor: "#1466C3",
      padding: "0.75rem 1.5rem",
      color: "#ffffff",
      border: "none",
      borderRadius: "5px",
      cursor: "pointer",
      transition: "all 0.3s ease-in-out",
    },
    buttonHover: {
      backgroundColor: "#0d4e91",
      transform: "scale(1.05)",
    },
    desktopLayout: {
      flexDirection: "row", // Text box on the right
    },
    mobileLayout: {
      flexDirection: "column", // Stack items vertically
      textAlign: "center",
    },
    textBox: {
      order: 2, // Ensures text box is on the right in desktop layout
      marginRight: "30px", // Move the text box closer to the right edge
    },
    textBoxMobile: {
      order: 1, // Ensures text box is centered in mobile layout
    },
    placeholderImage: {
      order: 1, // Ensures image is on the left in desktop layout
      width: "50%", // Adjust image size for desktop
      maxWidth: "500px",
    },
    placeholderImageMobile: {
      order: 2, // Ensures image stacks below the text box in mobile layout
      width: "100%", // Full-width for mobile
      marginBottom: "20px",
    },
  };

  const isMobile = window.innerWidth < 768;
  const buttonText = "Clase Gratis"; // Dynamic text for the button

  return (
    <section
      id="yoga"
      style={{
        ...styles.yogaSection,
        ...(isMobile ? styles.mobileLayout : styles.desktopLayout),
      }}
    >

      {/* Text Section */}
      <div
        style={{
          ...styles.contentBox,
          ...(isMobile ? styles.textBoxMobile : styles.textBox),
        }}
      >
        <h2 style={styles.title}>¡Clases de Yoga en FitInstinct!</h2>
        <p style={styles.content}>
          Nos emociona anunciar que en FitInstinct ahora ofrecemos{" "}
          <strong>clases de yoga</strong>. Todos los miembros pueden usar sus{" "}
          <strong>créditos de clase</strong> para participar. Mejora tu
          flexibilidad, reduce el estrés y encuentra tu equilibrio en nuestras
          clases. ¡Obtén tu clase gratis hoy!
        </p>
        <button
          style={styles.button}
          onClick={() => navigate("/register")}
          onMouseOver={(e) => {
            e.currentTarget.style.backgroundColor =
              styles.buttonHover.backgroundColor;
            e.currentTarget.style.transform = styles.buttonHover.transform;
          }}
          onMouseOut={(e) => {
            e.currentTarget.style.backgroundColor =
              styles.button.backgroundColor;
            e.currentTarget.style.transform = "scale(1)";
          }}
        >
          {buttonText}
        </button>
      </div>
    </section>
  );
}

export default YogaClasses;