import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

function Tecnologia() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const styles = {
    tecnologiaSection: {
      display: "flex",
      flexDirection: isMobile ? "column" : "row", // Stack vertically for mobile
      alignItems: "center",
      justifyContent: isMobile ? "flex-start" : "space-between", // Align for mobile
      padding: "30px 20px", // Adjusted padding to reduce gap
      backgroundColor: "#ffffff", // White background
      minHeight: "calc(100vh - 70px)", // Full height minus navbar height
    },
    textContainer: {
      flex: "1", // Take up equal space
      maxWidth: "600px",
      textAlign: isMobile ? "center" : "left", // Center text for mobile
      padding: "20px",
      marginBottom: isMobile ? "20px" : "0", // Space below text for mobile
    },
    title: {
      fontFamily: "Gendy, sans-serif",
      fontSize: "2rem",
      fontWeight: "bold",
      marginBottom: "20px",
    },
    content: {
      fontFamily: "Montserrat, sans-serif",
      fontSize: "1rem",
      lineHeight: "1.5",
      marginBottom: "20px",
    },
    bulletList: {
      listStyle: "none",
      padding: "0",
    },
    bulletItem: {
      display: "flex",
      alignItems: "center",
      marginBottom: "15px",
    },
    bulletIcon: {
      width: "40px",
      height: "40px",
      marginRight: "15px",
    },
    bulletText: {
      fontFamily: "Montserrat, sans-serif",
      fontSize: "1rem",
      lineHeight: "1.5",
    },
    button: {
      fontFamily: "Montserrat, sans-serif",
      fontSize: "1rem",
      fontWeight: "bold",
      backgroundColor: "#1466C3",
      borderColor: "#1466C3",
      textTransform: "uppercase",
      color: "#ffffff",
      border: "none",
      padding: "10px 20px",
      borderRadius: "5px",
      cursor: "pointer",
      transition: "all 0.3s ease-in-out", // Smooth transition for hover
      marginTop: "20px",
      textAlign: "center",
    },
    buttonHover: {
      backgroundColor: "#0d4e91", // Slightly darker blue
      transform: "scale(1.05)", // Slight enlargement
    },
    imageContainer: {
      flex: "1", // Take up equal space
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding: isMobile ? "0" : "20px", // Add padding for desktop
    },
    image: {
      width: isMobile ? "100%" : "auto", // Full width for mobile
      maxWidth: "600px", // Restrict width for desktop
      height: "auto",
      borderRadius: "10px", // Optional rounded corners
    },
  };

  return (
    <section id="tecnologia" style={styles.tecnologiaSection}>
      {/* Text Section */}
      <div style={styles.textContainer}>
        <h2 style={styles.title}>Tecnología</h2>
        <p style={styles.content}>
          En FitInstinct, transformamos tu entrenamiento con tecnología avanzada.
          A través de sensores de frecuencia cardíaca en tiempo real rastreamos
          lo siguiente:
        </p>
        <ul style={styles.bulletList}>
          <li style={styles.bulletItem}>
            <img
              src="/images/corriendonegro.png"
              alt="Zona de Intensidad"
              style={styles.bulletIcon}
            />
            <span style={styles.bulletText}>
              <strong>Zona de Intensidad:</strong> Determina tu zona de
              intensidad con base en datos biométricos e historial de entreno.
            </span>
          </li>
          <li style={styles.bulletItem}>
            <img
              src="/images/fuegonegro.png"
              alt="Calorías"
              style={styles.bulletIcon}
            />
            <span style={styles.bulletText}>
              <strong>Calorías:</strong> Identifica en tiempo real las calorías
              quemadas durante el entrenamiento.
            </span>
          </li>
          <li style={styles.bulletItem}>
            <img
              src="/images/masunonegro.png"
              alt="Puntaje"
              style={styles.bulletIcon}
            />
            <span style={styles.bulletText}>
              <strong>Puntaje:</strong> Obtén puntos a través de gamificación
              para mantenerte motivado.
            </span>
          </li>
        </ul>
        {/* Button */}
        <button
          style={styles.button}
          onClick={() => navigate("/register")}
          onMouseOver={(e) => {
            e.currentTarget.style.backgroundColor = styles.buttonHover.backgroundColor;
            e.currentTarget.style.transform = styles.buttonHover.transform;
          }}
          onMouseOut={(e) => {
            e.currentTarget.style.backgroundColor = styles.button.backgroundColor;
            e.currentTarget.style.transform = "scale(1)";
          }}
        >
          ¡Obtén tu clase gratis hoy!
        </button>
      </div>

      {/* Image Section */}
      <div style={styles.imageContainer}>
        <img
          src="/Comprimidas/heartrate.jpg"
          alt="Heart Rate Zones"
          style={styles.image}
        />
      </div>
    </section>
  );
}

export default Tecnologia;